import React from 'react'

import VideoPreview from '../video-preview'
import { NoVideo } from '../no-video'

import s from './index.module.sass'

function VideoPreviews (props) {
  const {
    previews,
    onClick
  } = props

  return (
    <div className={s.previewsContainer}>
      {previews && previews.length ? (
        previews.map(vp => (
          <div className={s.previewContainer}>
            <VideoPreview
              key={vp.id}
              isLive={vp.isLive}
              name={vp.description}
              date={vp.startTime_date}
              videoId={vp.videoId}
              courtId={vp.courtId}
              onClick={onClick(vp.videoId,vp.isLive)}
            />
          </div>
        ))
      ) : (
        <NoVideo/>
      )}
    </div>
  )
}

export default VideoPreviews
