import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyDy8OqVBjVtqsK3Ds_LICmGpL_NRAla_i0",
  authDomain: "bjornborg-b24cc.firebaseapp.com",
  databaseURL: "https://bjornborg-b24cc.firebaseio.com",
  projectId: "bjornborg-b24cc",
  storageBucket: "bjornborg-b24cc.appspot.com",
  messagingSenderId: "1059688203328"
};

firebase.initializeApp(config);
