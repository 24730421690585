import React, { Component } from 'react'

import firebase from 'firebase/app'

import Swal from 'sweetalert2'
import SVG from 'react-inlinesvg'

import Navbar from '../../../components/specific/header/navbar'
import Footer from '../../../components/specific/footer'
import { AppContext } from '../../../context'
import {deleteRequest, getRequest, getVideosToDelete, postRequest, sendToServer} from '../../../services/common'
// import SlackFeedback from 'react-slack-feedback'
import logo from '../../../assets/images/white_logo.png'
import airplayIcon from '../../../assets/icons/airplay.svg'
import playIcon from '../../../assets/icons/play-circle.svg'
import videoIcon from '../../../assets/icons/video.svg'

import s from './index.module.sass'
import '../../../components/common/custom.css'

const auth = firebase.auth();
const db = firebase.firestore();

const USERID_ADMIN_HARD_DELETE = "oPSZoMgGemOPq4YYea2ErorXWJm1";
//30 days of soft delete and 15 of hard
const NUM_DAYS_HARD_DELETE = 45;

class dashboard extends Component {
  static contextType = AppContext

  state = {
    dateTime: '',
    myVideos: null,
    liveRecordings: [],
    user: null,

    videosToDelete: [],
    VodsList: [],
    streamIds: []
  };

  handleEmailVerification (user) {
    if (!user) {
      return
    }

    if (!user.emailVerified) {
      this.sendUserMail(user)
    } else {
      console.log('Email has been verified')
      this.setState({emailVerified: true})
    }
  }

  deleteVideosToDelete(){
    {this.state.videosToDelete.forEach(async function(item, index) {
          console.log('delete firebase item : ', item);

          db.collection('sports')
              .doc('tennis')
              .collection('videos_deleted').doc(item.id).delete()

    }, this)}

  }

  getVideosToDelete(){
    db.collection('sports')
        .doc('tennis')
        .collection('videos_deleted')
        .get()
        .then(querySnapshot => {

          querySnapshot.forEach(doc => {
            let docInfo = doc.data();
            docInfo.id = doc.id;
            // console.log(doc.id);

            let startDateParsed = new Date(docInfo.startTime_date);
            console.log('doc start date of id ', doc.id ,' is : ', startDateParsed);

            let separator = "-";
            let day = new Date().getDate();
            let month = new Date().getMonth() + 1;
            let year = new Date().getFullYear();

            let start = new Date(`${year}${separator}${month}${separator}${day}`);

            console.log('current date is ', start.toString());

            start.setDate(start.getDate() - NUM_DAYS_HARD_DELETE);
            console.log('before that date videos are hard deleted ', start.toString());
            // console.log('startDateParsed < start' , startDateParsed < start);
            if (startDateParsed < start) {
               this.setState({
                videosToDelete: [...this.state.videosToDelete, docInfo]
              });
            }

          });
        });
  }

  async deleteStreamIds(){

    {this.state.videosToDelete.forEach(async function(item, index) {
      // console.log(item, index);
      // console.log(item.videoId);
      // console.log(this.state.VodsList);

      let result = this.state.VodsList.filter(obj => {
        return obj.streamId === item.videoId
      });

      //result found
      if(result[0]){
        //call to delete
        console.log('result of get vodId ' , result[0]['vodId']);
        console.log('result of get stream ' , result[0]['streamId']);

        const response = await postRequest('https://playreplay.antmedia.io:5443/LiveApp/rest/broadcast/deleteVoD/' + result[0]['vodId']);
        console.log(response);
      }


    }, this)}

  }

  async getVods() {
    const totalVideos = await getRequest('https://playreplay.antmedia.io:5443/LiveApp/rest/broadcast/getTotalVodNumber');
    // console.log('total:', totalVideos);

    const num_requests = (parseInt(totalVideos / 50) + 1);
    // console.log('requests ', num_requests);

    for (let i = 0; i < num_requests; i++) {
      let start = i * 50;
      let end = (i + 1) * 50;
      let url = 'https://playreplay.antmedia.io:5443/LiveApp/rest/broadcast/getVodList/' + start + '/' + end;
      // console.log(url);
      const videos = await getRequest(url);
      // console.log(videos);
      this.setState({
        VodsList: [...this.state.VodsList, ...videos]
      });

    }
    // console.log('print whole list', this.state.VodsList);

  }

  async hardDeleteVideos(){

    await this.getVods();
    this.getVideosToDelete();
  }

  async componentDidMount () {

    auth.onAuthStateChanged(user => {
      this.handleEmailVerification(user);
      this.setState({user: user});

      if (user && user.uid === USERID_ADMIN_HARD_DELETE) {
        console.log('go to delete');
        this.hardDeleteVideos()
      }
    });

  }

  sendUserMail (user) {
    user.sendEmailVerification()
      .then(() => { console.log('mail sent')})
      .catch(err => {
        console.error('An error occurred while trying to send verification email', err)
      })
  }

  sendEmailVerify (user) {

    Swal.fire({
      title: 'Email verification',
      text: 'In order to watch \'public archive\' and \'my-videos\' you need to verify your email address. We\'ve sent you an email.',
      type: 'warning'
    }).then(() => {
      this.sendUserMail(user)
    })

  }


  login(path){
    if (this.state.user) {

      if (!this.state.user.emailVerified) {
        this.sendEmailVerify(this.state.user)
        return
      }

      this.props.history.push({
        pathname: path,
        state: {page: 'dashboard'}
      })

    } else {
      const page = path.split('/').pop()
      this.props.history.push('/web/login/' + page)
    }
  }

  clickButton (path) {

    if (this.state.user) {
      this.login(path);
    }
    else{


      Swal.fire({
        title: 'Login required to access stored videos',
        // showCancelButton: true,
        // text: 'In order to watch stored videos, you have to login.',
        type: 'info',


      }).then((res) => {
        if(res.value){
          this.login(path);
        }

      })

    }
  };

  render () {

    if(this.state.videosToDelete.length !==0){
      // console.log('videooooos ', this.state.videosToDelete);
      this.deleteStreamIds();
      // this.deleteVideosToDelete();

    }

    return (
      <React.Fragment>
        <div className={`web-full-background`}>
          <Navbar/>
          <div className={s.outerContainer}>
            <img className={s.logo} src={logo} alt="logo"/>
            <div className={s.innerContainer}>
              <a className={`${s.boxItem} rounded shadow`} href="/web/dashboard/ongoing">
                <SVG src={airplayIcon}/>
                <div className={s.boxTitle}>Public Livestreams</div>
              </a>
              <a className={`${s.boxItem} rounded shadow pointer`}
                 onClick={this.clickButton.bind(this, '/web/archive')}
              >
                <SVG src={playIcon}/>
                <div className={s.boxTitle}>Public Videos</div>
              </a>
              <a
                className={`${s.boxItem} rounded shadow pointer`}
                onClick={this.clickButton.bind(this, '/web/my-videos')}
              >
                <SVG src={videoIcon}/>
                <div className={s.boxTitle}>My Videos</div>
              </a>
            </div>
          </div>
          <Footer/>
        </div>
        {/*<SlackFeedback*/}
        {/*  channel="#feedback"*/}
        {/*  user="Slack Feedback" // The logged in user (default = "Unknown User")*/}
        {/*  onSubmit={(payload, success, error) =>*/}
        {/*    sendToServer(payload, 'feedback', 'POST')*/}
        {/*      .then(success)*/}
        {/*      .catch(error)*/}
        {/*  }*/}
        {/*/>*/}
      </React.Fragment>
    )
  }
}

export default dashboard
