import React, { Component } from 'react'
import Navbar from '../../../components/specific/header/navbar'
import ClapprMediaPlayer from '../../../components/common/media-player'
import {downloadFile, getRequest, postRequest} from '../../../services/common'
import '../../../components/common/custom.css'
import Moment from 'react-moment'
import firebase from 'firebase/app'

import SVG from 'react-inlinesvg'

import s from './index.module.sass'

import downloadIcon from '../../../assets/icons/download.svg'
import deleteIcon from '../../../assets/icons/delete.svg'

import statisticsIcon from '../../../assets/icons/statistics.svg'
import Swal from "sweetalert2";

const httpsantMediaUrl = 'https://playreplay.antmedia.io:5443/LiveApp/streams/'
const db = firebase.firestore()

class StreamViewer extends Component {
  state = {
    videoSrc: '',
    video: null,
    loading: true,
    isPublic: null,
    isLive: null,
    comeFromPage:null,
    userId:null,
    VodsList:[]
  }

  retrieveVideo (videoId) {
    db.collection('sports')
      .doc('tennis')
      .collection('videos').where('videoId', '==', videoId)
      .get()
      .then(querySnapshot => {
        this.setState({
          video: querySnapshot.docs[0].data(),
          isPublic: querySnapshot.docs[0].data().isPublic,
          loading: false
        })

      })
  }

  componentDidMount () {
    const {
      match: {params}
    } = this.props;

    if(this.props.location.state) {
      localStorage.setItem('comeFromPage', this.props.location.state.page);

      this.setState({comeFromPage: this.props.location.state.page});
    }

    const localStorageGetItem = localStorage.getItem('comeFromPage');
    if(localStorageGetItem !== null){
      this.setState({comeFromPage: localStorageGetItem});

    }

    // console.log('come from page ' ,  this.state.comeFromPage);
    const videoId = params.videoId;
    const type = params.videoType;

    if (type === 'live') {
      this.setState({isLive: true})
    } else {
      this.setState({isLive: false})
    }
    // console.log('params', params)
    const url = StreamViewer.getStreamUrl(videoId, type)

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
          this.setState({
            userId: user.uid
          })
      }
    });

    this.setState({
      videoSrc: url,
    })

    this.retrieveVideo(videoId)
  }

  static getStreamUrl (videoId, type) {
    let url
    if (type === 'live') {
      url = httpsantMediaUrl + videoId + '.m3u8'
    } else {
      url = httpsantMediaUrl + videoId + '.mp4'
    }
    return url
  }

  static downloadVideo (url) {
    downloadFile(url)
  }

  makeVideoPublic(videoId){
    this.updatePublicStatus(videoId, true)
  }

  makeVideoPrivate(videoId){
    this.updatePublicStatus(videoId, false)
  }

  updatePublicStatus(videoId, isPublic){
    db.collection('sports')
        .doc('tennis')
        .collection('videos')
        .doc(videoId)
        .update({'isPublic': isPublic});

    this.setState({ isPublic: isPublic});

  }

  async deleteVideo(videoId){

    {Swal.fire({
      title: 'Delete video',
      text: 'Are you sure you want to delete this video? This action is irreversible',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then(async (result) => {

        if (result.value) {

          //COPY DOC to deleted collection
          db.collection('sports')
              .doc('tennis')
              .collection('videos_deleted')
              .doc(this.state.video.videoId).set({

            description: this.state.video.description,
            startTime_date: this.state.video.startTime_date,
            endTime_date: this.state.video.endTime_date,
            isLive: this.state.video.isLive,
            isPublic: this.state.video.isPublic,
            userId: this.state.video.userId,
            videoId: this.state.video.videoId
          }).then(() => {
            console.log("Document with id " + this.state.video.id + " successfully written in videos deleted collection!");
            return null;
          }).catch((error) => {
            console.error("Error writing document: ", error);
          });

          // DELETE DOC after copy
          db.collection('sports')
              .doc('tennis')
              .collection('videos')
              .doc(this.state.video.videoId).delete().then(() =>{
            console.log("Document successfully " + this.state.video.id + " deleted!");
            return null;
          }).catch((error) =>{
            console.error("Error removing document: ", error);
          });

          this.props.history.push('/web/my-videos')

        }
      }, this)}
  }

  render () {

    const description = this.state.video && this.state.video.description ? this.state.video.description : 'No description'
    const date = this.state.video && this.state.video.startTime_date ? this.state.video.startTime_date : 'No date'
    const courtId = this.state.video && this.state.video.courtId ? this.state.video.courtId : 'No court ID '

    return (
      <React.Fragment>
        <div>
          <Navbar/>
          <div className={`container`}>
            {/*{ !this.state.isLive*/}
            {/*    ? <a className={"purchase-button"} href="/web/info-pay">*/}
            {/*        <div className={"purchase-button-text"}>Would you like to purchase statistics?</div>*/}
            {/*    </a>*/}
            {/*    : ""*/}
            {/*}*/}
            <ClapprMediaPlayer source={this.state.videoSrc}/>
            {!this.state.loading && (
              <div className={s.mediaContent}>
                <div className={`size-3`}>
                  <span>{description}</span>
                </div>

                <div className={s.bottomWrapper}>
                  <div className={`grayest`}>
                    <Moment format="YYYY-MM-DD HH:mm" date={date}/>

                  </div>


                  {this.state.userId === this.state.video.userId
                      ? <a className={`${s.button} ${s.buttonMargin} icon-button`} href="#"
                           onClick={() => {this.deleteVideo(this.state.video.videoId)}}>
                        <SVG src={deleteIcon}/>
                        <span className={`icon-button-text`}>Delete</span>
                      </a>
                      : ""
                  }

                  {this.state.isPublic && this.state.userId === this.state.video.userId
                      ?
                      <a className={`${s.button} icon-button`} href="#" onClick={() => {this.makeVideoPrivate(this.state.video.videoId)}}>
                          <span className={`icon-button-text`}>Make Private</span>
                        </a>
                      : ""
                  }
                  {!this.state.isPublic && this.state.userId === this.state.video.userId
                      ? <a className={`${s.button} icon-button`} href="#" onClick={() => {this.makeVideoPublic(this.state.video.videoId)}}>
                        <span className={`icon-button-text`}>Make Public</span>
                      </a>
                      : ""
                  }

                  {this.state.comeFromPage === "my-videos" && this.state.userId === this.state.video.userId
                      ? <a className={`${s.button} icon-button`} href="#" onClick={() => {StreamViewer.downloadVideo(this.state.videoSrc)}}>
                            <SVG src={downloadIcon}/>
                            <span className={`icon-button-text`}>Download</span>
                          </a>
                      : ""
                  }

                  {this.state.comeFromPage === "my-videos" && this.state.userId !== this.state.video.userId
                      ? <a className={`${s.button} ${s.buttonMargin} icon-button`} href="#" onClick={() => {StreamViewer.downloadVideo(this.state.videoSrc)}}>
                        <SVG src={downloadIcon}/>
                        <span className={`icon-button-text`}>Download</span>
                      </a>
                      : ""
                  }


                    {/*<a className={`${s.button} icon-button`} href="#">*/}
                    {/*  <SVG src={statisticsIcon}/>*/}
                    {/*  <span className={`icon-button-text`}>Stastistics</span>*/}
                    {/*</a>*/}

                    {/*<button*/}
                    {/*  className="button"*/}
                    {/*  type="submit"*/}
                    {/*  onClick={() => {this.downloadVideo(this.state.videoSrc)}}>*/}
                    {/*  Download*/}
                    {/*</button>*/}
                </div>

                {/*<div className={s.bottomWrapper}>*/}
                {/*  <div className={`grayest`}>*/}
                {/*    {this.state.loading*/}
                {/*        ? ""*/}
                {/*        : <span>Court: {courtId}</span> }*/}
                {/*  </div>*/}
                {/*</div>*/}

              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default StreamViewer
