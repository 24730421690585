import React, { Component } from 'react'
import Moment from 'react-moment'
import fallbackImage from '../../../assets/images/nopreview.png'
import ReactImageFallback from 'react-image-fallback'

import playIcon from '../../../assets/icons/play-circle-video-preview.png'

import s from './index.module.sass'

class VideoPreview extends Component {
  render () {
    const imageSrc = 'https://playreplay.antmedia.io:5443/LiveApp/previews/' + this.props.videoId + '.png'
    return (
        <div className={s.previewWrapper}>
          <div className={s.preview} onClick={this.props.onClick}>
            <ReactImageFallback
              src={imageSrc}
              fallbackImage={fallbackImage}
              // initialImage="loader.gif"
              // alt="cool image should be here"
              className={s.previewImage}/>
            <img className={s.playIcon} src={playIcon}></img>
            {!!this.props.isLive ? (
              <span className={`${s.isLiveIndicator} rounded`}>
                <span className={s.isLiveIcon}></span>
                <span className={`size-6`}>Live</span>
            </span>
            ) : (
              ''
            )}
          </div>
          <div className={s.description}>
            {this.props.name ? this.props.name : 'No description'}
          </div>
          <div className={`${s.date} grayest`}>
            <Moment format="YYYY-MM-DD HH:mm" date={this.props.date}/>
          </div>
          {/* <div className="preview-last">
                  <span>Court No. : {this.props.courtId}</span>
              </div> */}
        </div>
    )
  }
}

export default VideoPreview
