import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { AppContext } from '../../../context.js'
import firebase from 'firebase'
import SVG from 'react-inlinesvg'

import logo from '../../../assets/images/logo.png'
import airplayIcon from '../../../assets/icons/airplay.svg'
import playIcon from '../../../assets/icons/play-circle.svg'
import videoIcon from '../../../assets/icons/video.svg'

import s from './index.module.sass'
import Swal from "sweetalert2";

class Navbar extends Component {
  static contextType = AppContext

  state = {
    isLoggedIn: null,
    user: null
  }

  logout = () => {
    this.context.user.logOut()
    this.props.history.push('/')
  }

  login = () => {
    this.props.history.push('/web/login/dashboard')
  }

  componentDidMount (): void {
    this.authListener()

  }

  login_redirect(path){
    if (this.state.user) {

      if (!this.state.user.emailVerified) {
        this.sendEmailVerify(this.state.user)
        return
      }

      this.props.history.push({
        pathname: path,
        state: {page: 'dashboard'}
      })

    } else {
      const page = path.split('/').pop()
      this.props.history.push('/web/login/' + page)
    }
  }

  clickButton (path) {

    if (this.state.user) {
      this.login_redirect(path);
    }
    else{


      Swal.fire({
        title: 'Login required to access stored videos',
        // showCancelButton: true,
        // text: 'In order to watch stored videos, you have to login.',
        type: 'info',


      }).then((res) => {
        if(res.value){
          this.login_redirect(path);
        }

      })

    }
  };

  renderNavigationButtons () {
    return (
      <div className={s.tabsWrapper}>
        <a className={`${s.tab} icon-button pointer`} href="/web/dashboard/ongoing">
          <SVG src={airplayIcon}/>
          <span className={`icon-button-text`}>Public Livestreams</span>
        </a>
        <a className={`${s.tab} icon-button pointer`} onClick={this.clickButton.bind(this, '/web/archive')}>
          <SVG src={playIcon}/>
          <span className={`icon-button-text`}>Public Videos</span>
        </a>
        <a className={`${s.tab} icon-button pointer`} onClick={this.clickButton.bind(this, '/web/my-videos')}>
          <SVG src={videoIcon}/>
          <span className={`icon-button-text`}>My Videos</span>
        </a>
      </div>
    )
  }

  renderLogState (menuName, onClick, buttonClasses = []) {
    if (this.state.isLoggedIn === null) {
      return null
    }

    const classString = buttonClasses.join(' ')

    return (
      <div className={s.loginLogoutWrapper}>
        <span className={`button ${classString} pointer`} onClick={onClick}>{menuName}</span>
      </div>
    )
  }

  renderLoggedInState (buttonClasses) {
    return this.renderLogState('Logout', this.logout, buttonClasses)
  }

  renderLoggedOutState (buttonClasses) {
    return this.renderLogState('Login', this.login, buttonClasses)
  }

  authListener () {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({isLoggedIn: true})
        this.setState({user: user})
      } else {
        this.setState({isLoggedIn: false})
      }
    })

  }

  render () {
    const location = this.props.location.pathname
    const onlyLoginLogoutNavbar = (
      <nav className={s.navbar}>
        {this.state.isLoggedIn
          ? this.renderLoggedInState(['on-dark-background'])
          : this.renderLoggedOutState(['on-dark-background'])
        }
      </nav>
    )
    const fullNavbar = (
      <nav className={`${s.navbar} ${s.isFull}`}>
        <a className={s.logoLink} href="/">
          <img className={s.logo} src={logo} alt="logo"/>
        </a>

        {location === '/'
          ? ''
          : this.renderNavigationButtons()}

        {this.state.isLoggedIn
          ? this.renderLoggedInState()
          : this.renderLoggedOutState()
        }
      </nav>
    )

    return (
      <header>
        {location === '/' ? onlyLoginLogoutNavbar : fullNavbar}
      </header>
    )
  }
}

export default withRouter(Navbar)
