import React, { Component } from "react";

import Navbar from "../../../components/specific/header/navbar";

import firebase from "firebase/app";
import Swal from "sweetalert2";
import VideoPreviews from '../../../components/common/video-previews'
import {getClientToken} from "../../../services/common";
const db = firebase.firestore();


class MyVideos extends Component {
  state = {
    dateTime: "",
    myVideos: null
  };

  warningNotVerified() {
    Swal.fire({
      title: "Email verification",
      text: "In order to watch 'public archive' and 'my-videos' you need to verify your email address. We've sent you an email.",
      type: "warning"
    }).then(() => { });
  }

  retrieveData(user){
    db.collection('sports')
        .doc('tennis')
        .collection('videos')
        .limit(20)
        .where('userId', '==', user.uid)
        .orderBy('startTime_date', 'desc')
        .onSnapshot(querySnapshot => {

          this.setState({ myVideos: [] });
          querySnapshot.forEach(doc => {
            let docInfo = doc.data();
            docInfo.id = doc.id;
            this.setState({
              myVideos: [...this.state.myVideos, docInfo]
            });
          });
        });
  }

  componentDidMount() {

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (!user.emailVerified) {
          this.props.history.push('/');
          this.warningNotVerified();
          return
        }
        this.retrieveData(user);
      }
    });

  }

  startViewer = (videoId, isLive) => {
    let type = "persisted";
    if(isLive) {
      type = "live";
    }

    return () => {
      this.props.history.push({
        pathname: "/web/stream/" + videoId + "/"+ type,
        state: {page: 'my-videos'}
      })
      // this.props.history.push("/web/stream/" + videoId + "/"+ type);
    };
  };

  render() {
    const { myVideos } = this.state;

    return (
      <React.Fragment>
        <div>
          <Navbar/>
          <div className={`container`}>
            <h2>My Videos</h2>
            <VideoPreviews
              previews={myVideos}
              onClick={this.startViewer}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MyVideos;
