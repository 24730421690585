import React from 'react';
import '../../components/common/custom.css';

import Navbar from "../../components/specific/header/navbar";


class InfoPayment extends React.PureComponent {

  constructor(props) {
    super(props);

  }

  componentDidMount() {
  }

  render() {
    return (

        <React.Fragment>
          <div className={`web-full-background stream-detail-page`}>
              <Navbar />
              <div className={"info-pay-root"}>
                  <div className={"info-pay"}>
                      <span> Info by Mike James</span>
                  </div>

                  <div className={"info-pay-message"}>
                      <span> At this point, we can only offer to provide match statistics on match play.
                          If your video does not represent a match, no meaningful outcome may be generated</span>
                  </div>

              </div>

              <div>

                  <a className={"go-back-button"} onClick={this.props.history.goBack} href={"#"}>
                      <div className={"go-back-button-text"}>Go Back</div>
                  </a>

                  <a className={"continue-purchase-button"} href="/web/pay">
                      <div className={"continue-purchase-button-text"}>Continue to payment</div>
                  </a>

              </div>

          </div>
        </React.Fragment>



    );
  }
}

export default InfoPayment;
