import React from 'react'
import firebase from 'firebase/app'

const auth = firebase.auth();

export const DEFAULT_CONTEXT = {
  user: {
    isLoggedIn: false,
    data: {},
    logOut () {
      auth.signOut()
    },
  },
};

export const AppContext = React.createContext(DEFAULT_CONTEXT);
