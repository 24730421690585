import React from 'react'
import Clappr from 'clappr'

import s from './index.module.sass'

export default class ClapprMediaPlayer extends React.Component {

  shouldComponentUpdate (nextProps, nextState) {
    let changed = (nextProps.source !== this.props.source)
    this.state = nextState
    if (changed) {
      this.change(nextProps.source)
    }
    return false
  }

  componentDidMount () {
    this.change(this.props.source)
  }

  change (source) {
    if (this.player) {
      this.destroyPlayer()
    }

    // console.log('player', this.refs.player);
    this.player = new Clappr.Player({
      parentId: '#media',
      source: source,
      width: '100%',
      height: '100%',
      autoPlay: true,
      hlsjsConfig: {
        enableWorker: true,
      }
    })
  }

  destroyPlayer () {
    if (this.player) {
      this.player.destroy()
    }
    this.player = null
  }

  componentWillUnmount () {
    this.destroyPlayer()
  }

  render () {
    return (
      <div id="media" className={s.media}>
        <div ref="player"/>
      </div>
    )
  }
}
