import React from 'react';
import '../../components/common/custom.css';

import CardPaymentView  from './cardPaymentView'
class PaymentForm extends React.PureComponent {

  state = {
      cardPayView:null,

      descriptionValue:"",
      validateDescription:null,

      checkedTermsAndConditions:false,
      checkedConfirm:false,
      validateCheckboxTermsAndConditions:null,
      validateCheckboxConfirm:null,

      successfulTransaction:null

  };

  constructor(props) {
    super(props);
    this.cardPayment = React.createRef();
  }

  componentDidMount() {
  }

  async buyNow(){
      let nonce = null;
      try{
          nonce = await this.cardPayment.current.getToken();
      }catch (e) {
          console.log(e);
      }

      if(this.state.descriptionValue === ""){
          this.setState({validateDescription :true});
      }

      if(!this.state.checkedTermsAndConditions){
          this.setState({validateCheckboxTermsAndConditions :true});
      }
      if(!this.state.checkedConfirm){
          this.setState({validateCheckboxConfirm :true});
          return
      }

      if (nonce === null) {
          console.log('error in token');
          return
      }

      const response = await this.cardPayment.current.buy(nonce);
      if(response.success){
          this.setState({successfulTransaction:true});
      }
      console.log(response);
  }

  handleCheckboxChangeTerms(event) {
      if (event.type === 'change') {
          const isChecked = event.currentTarget.checked;

          this.setState({checkedTermsAndConditions:isChecked});
          if(isChecked){
              this.setState({validateCheckboxTermsAndConditions :false});
          }
      }
  }

    handleCheckboxChangeConfirm(event) {
        if (event.type === 'change') {
            const isChecked = event.currentTarget.checked;
            this.setState({checkedConfirm:isChecked});

            if(isChecked){
                this.setState({validateCheckboxConfirm :false});
            }
        }
    }

    handleChangeDescription(event) {
      const value = event.target.value;
      this.setState({descriptionValue: value});

      if(value !== ""){
          this.setState({validateDescription :false});
      }
    }

  render() {

    return (

        <React.Fragment >

             <div className={"payment-steps-root"}>

               <h1 >You're Almost there</h1>
               <div className={"payment-step"}>
                 <p className={"payment-divider"}> </p>

                 <span className={"payment-headers"}>1. Choose Plan</span>

                 <div className="form-check radio-input-div">
                   <label >
                     <input
                         type="radio"
                         name="radio-button"
                         value="full-match"
                         checked={true}
                         onChange={()=>{}}
                         className="form-check-input radio-input"
                     />
                     Full match statistics, 110 EUR per match (independant of match length)
                   </label>
                 </div>

                 <div >
                     <label htmlFor="input-description">Player Description</label>

                     <input className={"button-description"}
                         type="text"
                         id={"input-description"}
                         value={this.state.descriptionValue}
                         onChange={this.handleChangeDescription.bind(this)}
                         placeholder={"describe the player you want statistics"}
                     />
                     {this.state.validateDescription
                         ? <div className={"label-terms-validation"}>
                             <label >Please fill the description</label>
                         </div>
                         : ""}
                 </div>


               </div>

               <div id={"step-2"}>
                  <p className={"payment-divider"}> </p>
                  <span className={"payment-headers"}>2. Enter payment details</span>

                   <div>
                       <a href="https://www.braintreegateway.com/merchants/fs2wbzs5cprmp7vx/verified" target="_blank">
                           <img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png" className={"braintree-verify"}/>
                       </a>
                   </div>
                   <CardPaymentView ref={this.cardPayment}/>
               </div>

             </div>

              <div className={"payment-summary-root"}>
                <div className={"payment-summary"}>

                    <div className={"payment-summary-billed-Summary"}>
                        <span>Billed Summary</span>
                    </div>

                    <div className={"payment-summary-bill"}>
                        <span >Billed now : 110 Euros</span>
                    </div>

                    <div className={"payment-summary-terms-info"}>
                        <span >Your payment data is encrypted and secure. All amounts shown are in EUR.</span>
                    </div>

                    <div>

                        <input type="checkbox"
                               id={"terms-checkbox"}
                               defaultChecked={this.state.checkedTermsAndConditions}
                               onChange={this.handleCheckboxChangeTerms.bind(this)} />
                        <label className={"label-terms"} htmlFor={"terms-checkbox"}>I agree to the terms and conditions</label>

                    </div>

                    {this.state.validateCheckboxTermsAndConditions
                        ? <div className={"label-terms-validation"}>
                            <label >Please agree to terms and conditions</label>
                        </div>
                        : ""}

                    <div className={"checkbox-confirm"}>

                        <input type="checkbox"
                               id={"confirm-checkbox"}
                               defaultChecked={this.state.checkedConfirm}
                               onChange={this.handleCheckboxChangeConfirm.bind(this)} />
                        <label className={"label-terms"} htmlFor={"confirm-checkbox"}>I confirm that I understand that only matches can be analyzed
                            and not any practice/freeplay.
                        </label>

                    </div>

                    {this.state.validateCheckboxConfirm
                        ? <div className={"label-terms-validation"}>
                            <label >Please agree to terms and conditions</label>
                        </div>
                        : ""}


                    <div className="braintree-footer">
                        <a className={"payment-go-back-button"} onClick={this.props.history.goBack} href={"#"}>
                            <div className={"payment-go-back-button-text"}>Go Back</div>
                        </a>

                        {this.state.successfulTransaction
                            ? <div className={"label-success"}>
                                <label >Success. Enjoy your product! </label>
                            </div>
                            : <a className={"payment-button"} onClick={this.buyNow.bind(this)} href="#">
                                <div className={"payment-button-text"}>Buy now</div>
                            </a>}

                    </div>
                </div>
              </div>

        </React.Fragment>

    );
  }
}

export default PaymentForm;


