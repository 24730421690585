import React, { Component } from 'react'

import Navbar from '../../../components/specific/header/navbar'

import firebase from 'firebase/app'
import VideoPreviews from '../../../components/common/video-previews'

const db = firebase.firestore()

class OngoingStreams extends Component {
  state = {
    dateTime: '',
    liveRecordings: []
  }

  retrieveData () {
    db.collection('sports')
      .doc('tennis')
      .collection('videos')
      .limit(8)
      .where('isPublic', '==', true)
      .where('isLive', '==', true)
      .orderBy('startTime_date', 'desc')
      .onSnapshot(querySnapshot => {

        this.setState({liveRecordings: []})
        querySnapshot.forEach(doc => {
          let docInfo = doc.data()
          docInfo.id = doc.id
          this.setState({
            liveRecordings: [...this.state.liveRecordings, docInfo]
          })
        })
      })
  }

  componentDidMount () {
    this.retrieveData()
  }

  startViewer = videoId => {
    return () => {
      this.props.history.push('/web/stream/' + videoId + '/live')
    }
  }

  render () {
    const {liveRecordings} = this.state

    return (
      <React.Fragment>
        <div>
          <Navbar/>
          <div className={`container`}>
            <h2>Public Livestreams</h2>
            <VideoPreviews
              previews={liveRecordings}
              onClick={this.startViewer}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default OngoingStreams
