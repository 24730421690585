import React, { Component } from "react";
import { Button, Container, Grid } from "semantic-ui-react";

class TermsAndConditions extends Component {
  handleClick = () => {
    this.props.history.push("/web/login");
  };

  render() {
    return (
      <Container>
        <Grid>
          <Grid.Row>
            <div
              className={`tablet-pages`}
              dangerouslySetInnerHTML={{
                __html: `<p><strong>Anv&auml;ndarvillkor (terms of service)</strong></p>
<p><strong>1. Allm&auml;nt</strong></p>
<p>1.1. Bakgrund</p>
<p>Good to Great Tennis Academy: GoodToGreat World AB, 556812-7095 (Ben&auml;mns &rdquo;GTG&rdquo;) samt PlayReplay AB, 559199-3554 (Ben&auml;mns PRP) tillhandah&aring;ller en tj&auml;nst f&ouml;r &rdquo;Live-streaming&rdquo; och &rdquo;Video-On-Demand&rdquo; vid Catella Arena, Rinkebyv&auml;gen 20, 182 36 Danderyd, samt genom GTGs och PRPs &rdquo;Medier&rdquo;. Syftet med tj&auml;nsten &auml;r att slutanv&auml;ndare skall kunna s&auml;nda, spara, och spela upp audivisuella upptagningar till en begr&auml;nsad krets eller allm&auml;nheten via internet.</p>
<p>1.2 Huvudsakliga Definitioner</p>
<ol type="i">
<li>
<p><span style="color: #000000;">&rdquo;GTG&rdquo; avser f&ouml;retaget GoodToGreat World AB, 556812-7095 </span></p>
</li>
<li>
<p><span style="color: #000000;">&rdquo;PRP&rdquo; avser f&ouml;retaget PlayReplay AB, 559199-3554</span></p>
</li>
<li>
<p><span style="color: #000000;">&rdquo;Live- Streaming&rdquo; inneb&auml;r att en samtida, digital &ouml;verf&ouml;ring av materialet fr&aring;n PRP via Internet sker till en anv&auml;ndare som begagnar Internet p&aring; ett s&aring;dant s&auml;tt att data kan uppfattas i realtid. </span></p>
</li>
<li>
<p><span style="color: #000000;">&rdquo;Video-On-Demand&rdquo; avser inspelning, kopiering, f&ouml;rvaring, och uppspelning av digitalt material fr&aring;n PRP eller GTG via Internet. </span></p>
</li>
<li>
<p><span style="color: #000000;">&rdquo;Medier&rdquo; innefattar (utan att begr&auml;nsas till) App, och/eller Webb-App, och/eller Webbsida</span></p>
</li>
<li>
<p><span style="color: #000000;">&rdquo;Tj&auml;nsten&rdquo; &auml;r en kollektiv ben&auml;mning av alla tj&auml;nster, t.ex. &rdquo;Live-Streaming&rdquo;, &rdquo;Video-On-Demand&rdquo; som tillhandah&aring;lls via GTGs och PRPs Medier. &rdquo;Tj&auml;nsten&rdquo; refererar &auml;ven till Medierna sj&auml;lvt.</span></p>
</li>
<li>
<p><span style="color: #000000;">&rdquo;Villkor&rdquo; avser anv&auml;ndarvillkor f&ouml;r tj&auml;nsten</span>. Villkoren <span style="color: #000000;">&auml;r dokumenterade i detta avtal</span></p>
</li>
<li>
<p>&ldquo;Integritetspolicy&rdquo; avser integritetspolicyn (eng. privacy policy) f&ouml;r anv&auml;ndande av tj&auml;nsten. Integritetspolicyn finns att tillg&aring; p&aring; <span style="color: #0563c1;"><u><a href="https://gtg.playreplay.io/gtg/privacy-policy">https://gtg.playreplay.io/gtg/privacy-policy</a></u></span>.</p>
</li>
</ol>
<p>&nbsp;</p>
<p>1.3. Kontaktuppgifter</p>
<p><a name="_GoBack"></a> All kontakt med PRP sker skall ske via e-mail: <a href="mailto:hey@playreplay.io"><span style="color: #0563c1;"><u>hey@playreplay.io</u></span></a>. All kontakt med GTG skall ske via e-mail: <a href="mailto:info@gtg.se"><span style="color: #0563c1;"><u>info@gtg.se</u></span></a></p>
<p><strong>2. Godk&auml;nnande av Villkoren</strong></p>
<p>2.1 F&ouml;r att anv&auml;nda Tj&auml;nsten m&aring;ste du f&ouml;rst godk&auml;nna Villkoren samt Integritetspolicyn samt intyga att du uppfyller de krav som Villkoren st&auml;ller p&aring; dig som anv&auml;ndare. Om du inte godk&auml;nner Villkoren har du inte r&auml;tt att anv&auml;nda Tj&auml;nsten.</p>
<p>2.2 Du &auml;r inf&ouml;rst&aring;dd med och accepterar att GTG och PRP kommer att betrakta din anv&auml;ndning av Tj&auml;nsten som ett godk&auml;nnande av Villkoren fr&aring;n den tidpunkt som du anv&auml;nder tj&auml;nsten och fram&aring;t. Anv&auml;ndande av tj&auml;nsten kan till exempel innefatta (men begr&auml;nsas inte till) att skapa ett anv&auml;ndarkonto, att anv&auml;nda tj&auml;nst f&ouml;r Live-streaming och/eller Video-On-Demand via GTGs eller PRPs medier.</p>
<p>2.3 Du intygar att du &auml;r 18 &aring;r eller &auml;ldre.</p>
<p><strong>3. Villkors&auml;ndringar</strong></p>
<p>PRP och GTG f&ouml;rbeh&aring;ller sig r&auml;tten att g&ouml;ra &auml;ndringar i Villkoren. D&auml;rf&ouml;r m&aring;ste du regelbundet titta p&aring; Villkoren samt Integritetspolicyn f&ouml;r att kontrollera eventuella f&ouml;r&auml;ndringar. Den modifierade versionen av Villkoren (de &rdquo;Modifierade Villkoren&rdquo;) kommer att publiceras p&aring; <span style="color: #0563c1;"><u><a href="https://gtg.playreplay.io/gtg/terms-conditions">https://gtg.playreplay.io/gtg/terms-conditions</a></u></span> och de modifierade versionen av integritetspolicyn (den &ldquo;Modifierade Integritetspolicyn&rdquo;) kommer att publiceras p&aring; <span style="color: #0563c1;"><u><a href="https://gtg.playreplay.io/gtg/privacy-policy">https://gtg.playreplay.io/gtg/privacy-policy</a></u></span>. Om du inte accepterar de Modifierade Villkoren eller den Modifierade Integritetspolicyn m&aring;ste du sluta anv&auml;nda Tj&auml;nsten. Din fortsatta anv&auml;ndning av Tj&auml;nsten efter det datum de Modifierade Villkoren eller den Modifierade Integritetspolicyn gjorts tillg&auml;ngliga kommer att anses utg&ouml;ra ditt godk&auml;nnande av de Modifierade Villkoren och den Modifierade Integritetspolicyn .</p>
<p><strong>4. Anv&auml;ndarkonto</strong></p>
<p>4.1 F&ouml;r att f&aring; tillg&aring;ng till vissa delar av Tj&auml;nsten m&aring;ste du skapa ett Anv&auml;ndarkonto. N&auml;r du skapar ditt konto m&aring;ste du tillhandah&aring;lla korrekt och fullst&auml;ndig information. Det &auml;r viktigt att du f&ouml;rvarar l&ouml;senordet till ditt anv&auml;ndarkonto s&auml;kert och konfidentiellt.</p>
<p>4.2 Du m&aring;ste meddela PRP omg&aring;ende om du f&aring;r k&auml;nnedom om n&aring;gon s&auml;kerhets&ouml;vertr&auml;delse eller obeh&ouml;rig anv&auml;ndning av ditt Anv&auml;ndarkonto.</p>
<p>4.3 Du godk&auml;nner att du &auml;r ensamt ansvarig (i f&ouml;rh&aring;llande till PRP, GTG och andra) f&ouml;r all aktivitet som f&ouml;rekommer genom ditt Anv&auml;ndarkonto.</p>
<p>4.4 Du &auml;r medveten om att PRP eller GTG n&auml;rsomhelst kan avsluta och st&auml;nga ner ditt anv&auml;ndarkonto om du bryter mot Villkoren eller om PRP eller GTG bed&ouml;mer att du kan utg&ouml;ra en risk f&ouml;r andra anv&auml;ndare</p>
<p><strong>5. Allm&auml;nna restriktioner i anv&auml;ndningen</strong></p>
<p>5. Allm&auml;nna restriktioner i anv&auml;ndningen</p>
<p>5.1 PRP och GTG beviljar dig h&auml;rigenom tillg&aring;ng till och till&aring;telse att anv&auml;nda Tj&auml;nsten under f&ouml;ruts&auml;ttning att f&ouml;ljande uttryckliga villkor efterlevs, och du godk&auml;nner att din underl&aring;tenhet att uppfylla n&aring;got av dessa villkor utg&ouml;r ett brott av Villkoren fr&aring;n din sida:</p>
<p>5.1.1 Du f&ouml;rbinder dig att inte sprida n&aring;gon del eller n&aring;gra delar av Tj&auml;nsten, innefattande men inte begr&auml;nsat till n&aring;got Inneh&aring;ll, i n&aring;got medium utan PRPs skriftliga till&aring;telse om inte PRP m&ouml;jligg&ouml;r s&aring;dan distribution genom funktioner i Tj&auml;nsten (exempelvis genom att s&auml;nda tennismatch via Websidan);</p>
<p>5.1.2. Du f&ouml;rbinder dig att inte f&ouml;r&auml;ndra eller modifiera n&aring;gon del av Tj&auml;nsten (innefattande men inte begr&auml;nsad till Appen, Webb-Appen, Webbsidan och d&auml;rtill relaterad teknologi);</p>
<p>5.1.3. Du f&ouml;rbinder dig att inte skaffa &aring;tkomst till Inneh&aring;ll genom n&aring;gon annan teknologi eller n&aring;got annat medel annat &auml;n med Webbplatsens videouppspelningssidor, webappen eller s&aring;dana andra funktioner som PRP uttryckligen kan ha angivit f&ouml;r detta syfte.</p>
<p>5.1.4. Du f&ouml;rbinder dig att inte (samt inte f&ouml;rs&ouml;ka) kringg&aring;, avaktivera eller i p&aring; annat s&auml;tt st&ouml;ra Tj&auml;nsten s&auml;kerhetsrelaterade funktioner eller funktioner som (i) f&ouml;rhindrar eller begr&auml;nsar anv&auml;ndning eller kopiering av Inneh&aring;ll eller (ii) inskr&auml;nker anv&auml;ndningen av Tj&auml;nsten eller material tillg&auml;ngligt via Tj&auml;nsten;</p>
<p>5.1.5. Du f&ouml;rbinder dig att inte anv&auml;nda Tj&auml;nsten f&ouml;r n&aring;got av f&ouml;ljande kommersiella syften om du inte i har skriftligt godk&auml;nnande fr&aring;n PRP eller GTG.</p>
<ol type="i">
<li>
<p><span style="color: #000000;">F&ouml;rs&auml;ljning av tillg&aring;ng till Tj&auml;nsten;</span></p>
</li>
<li>
<p><span style="color: #000000;">F&ouml;rs&auml;ljning av reklam, sponsring eller marknadsf&ouml;ringsplats p&aring; eller inom Inneh&aring;llet;</span></p>
</li>
<li>
<p><span style="color: #000000;">F&ouml;rs&auml;ljning av reklam, sponsring eller marknadsf&ouml;ringsplats p&aring; en blogg eller webbplats som till&aring;ter reklam och marknadsf&ouml;ring och som inneh&aring;ller Inneh&aring;ll levererat via Tj&auml;nsten</span></p>
</li>
</ol>
<p>5.1.6. Du f&ouml;rbinder dig att inte anv&auml;nda eller starta n&aring;got automatiskt system (innefattande, men utan begr&auml;nsning till, n&aring;gon robot, spindel eller offline reader) som vid anv&auml;ndning av Tj&auml;nsten skickar meddelanden till PPR och/eller PRPs servrar mer frekvent &auml;n en m&auml;nniska kan &aring;stadkomma inom samma tid genom att anv&auml;nda en allm&auml;nt tillg&auml;nglig standard webbl&auml;sare (d.v.s. som inte &auml;r modifierad);</p>
<p>5.1.7 Du f&ouml;rbinder dig att inte insamla n&aring;gra personuppgifter (detta innefattar namn p&aring; Anv&auml;ndarkonton eller namn som visas p&aring; Webbsidan) tillh&ouml;rande n&aring;gon anv&auml;ndare av Appen, Wepp-Appen, Webbsidan, eller Tj&auml;nsten;</p>
<p>5.1.8 Du f&ouml;rbinder dig att inte anv&auml;nda Tj&auml;nsten f&ouml;r att p&aring;kalla aff&auml;rsm&ouml;jligheter eller kommersiella initiativ;</p>
<p>5.1.9 Du f&ouml;rbinder dig att inte, genom att ta del av Anv&auml;ndarbidrag, s&ouml;ka v&auml;rva anv&auml;ndare av Webbplatsen f&ouml;r kommersiella syften; och</p>
<p>5.1.10 Du f&ouml;rbinder dig att inte bereda dig tillg&aring;ng till Inneh&aring;ll annat &auml;n i din personliga, icke-kommersiella anv&auml;ndning som avsett och till&aring;tet genom Tj&auml;nstens normala funktionalitet.</p>
<p>5.1.11 Du f&ouml;rbinder dig att inte kopiera, reproducera, distribuera, s&auml;nda, visa, s&auml;lja, licensiera eller p&aring; annat s&auml;tt utnyttja n&aring;got Inneh&aring;ll f&ouml;r n&aring;got annat &auml;ndam&aring;l utan f&ouml;reg&aring;ende skriftligt medgivande fr&aring;n PRP eller respektive licensinnehavare till Inneh&aring;llet eller anv&auml;ndare som har skapat inneh&aring;llet.</p>
<p>5.2 PRP f&ouml;rnyar sig oavbrutet i syfte att tillhandah&aring;lla den b&auml;sta m&ouml;jliga upplevelsen f&ouml;r dess anv&auml;ndare. Du f&ouml;rst&aring;r och godk&auml;nner att Tj&auml;nsten som PRP tillhandah&aring;ller b&aring;de i sin form och natur kan komma att f&ouml;r&auml;ndras fr&aring;n tid till annan utan att du informeras i f&ouml;rv&auml;g om &auml;ndringar.</p>
<p>5.3 Som en del i denna p&aring;g&aring;ende f&ouml;rnyelse, &auml;r du inf&ouml;rst&aring;dd med och accepterar att PRP efter eget gottfinnande kan upph&ouml;ra (permanent eller tillf&auml;lligt) med att tillhandah&aring;lla Tj&auml;nsten (eller andra funktioner r&ouml;rande Tj&auml;nsten) till dig eller till anv&auml;ndare i allm&auml;nhet utan att i f&ouml;rv&auml;g meddela dig om detta. Du kan n&auml;r som helst upph&ouml;ra med din anv&auml;ndning av Tj&auml;nsten och du &auml;r inte skyldig att informera PRP n&auml;r du v&auml;ljer att avsluta din anv&auml;ndning.</p>
<p>5.4 Du godk&auml;nner att du &auml;r ensamt ansvarig f&ouml;r (och att PRP inte har n&aring;got ansvar mot dig eller n&aring;gon tredje part f&ouml;r) alla &ouml;vertr&auml;delser av dina skyldigheter under Villkoren och f&ouml;r konsekvenserna (innefattande f&ouml;rlust eller skada som PRP kan &aring;samkas) av s&aring;dana &ouml;vertr&auml;delser.</p>
<p><strong>6. Inneh&aring;ll</strong></p>
<p>6.1 Som innehavare av ett Anv&auml;ndarkonto kan du skapa och ladda upp ett Inneh&aring;ll n&auml;r du anv&auml;nder Tj&auml;nsten. Du &auml;r inf&ouml;rst&aring;dd med att oavsett om s&aring;dant Inneh&aring;ll publiceras eller ej garanterar inte PRP att Inneh&aring;llet h&aring;lls konfidentiellt.</p>
<p>6.2 Du bibeh&aring;ller din &auml;gander&auml;tt till ditt Inneh&aring;ll, men &auml;r skyldig att bevilja begr&auml;nsade licensr&auml;ttigheter till PRP och andra anv&auml;ndare av Tj&auml;nsten. Dessa r&auml;ttigheter beskrivs i avsnitt 8 i dessa Villkor (R&auml;ttigheter du licensierar).</p>
<p>6.3 Du &auml;r medveten om och accepterar att du &auml;r ensamt ansvarig f&ouml;r ditt Inneh&aring;ll och konsekvenserna av att posta eller publicera dem. PRP st&aring;r inte bakom eller st&ouml;djer n&aring;got Inneh&aring;ll eller n&aring;gon &aring;sikt, rekommendation eller r&aring;d som uttrycks d&auml;ri och PRP &auml;r under inga omst&auml;ndigheter ansvarig f&ouml;r Inneh&aring;llet.</p>
<p>6.4 Du garanterar att du innehar (och kommer forts&auml;tta att inneha under din fortsatta anv&auml;ndning av Tj&auml;nsten) alla erforderliga licenser, r&auml;ttigheter, samtycken och till&aring;telser som &auml;r n&ouml;dv&auml;ndiga f&ouml;r att PRP skall kunna anv&auml;nda ditt Inneh&aring;ll i syfte att tillhandah&aring;lla Tj&auml;nsten och i &ouml;vrigt f&ouml;r att kunna anv&auml;nda ditt Inneh&aring;ll p&aring; det s&auml;tt som avses med Tj&auml;nsten och dessa Villkor.</p>
<p>6.5 Du f&ouml;rbinder dig att inte skapa eller l&auml;gga upp n&aring;got Inneh&aring;ll som inkluderar material som &auml;r olagligt f&ouml;r dig att inneha i det land d&auml;r du &auml;r bosatt, eller som det vore olagligt f&ouml;r PRP att anv&auml;nda eller inneha i samband med tillhandah&aring;llandet av Tj&auml;nsten.</p>
<p>6.6 Du accepterar att Inneh&aring;ll som du bidrar med till Tj&auml;nsten inte inneh&aring;ller upphovsr&auml;ttsligt skyddat material tillh&ouml;rande tredje part eller material som &auml;r f&ouml;rem&aring;l f&ouml;r n&aring;gon tredje parts &auml;gander&auml;tt (innefattande r&auml;tt till privatliv och r&auml;tt till publicitet) s&aring;vida du inte har till&aring;telse fr&aring;n den r&auml;ttm&auml;tige &auml;garen att anv&auml;nda materialet ifr&aring;ga eller p&aring; annat s&auml;tt har en juridisk r&auml;tt att l&auml;gga upp materialet och bevilja PRP den licens som anges under punkten 8.1 nedan.</p>
<p>6.7 Vid misstanke om eventuellt missbruk av dessa Villkor f&ouml;rbeh&aring;ller sig PRP r&auml;tten (men &auml;r inte skyldigt) att avg&ouml;ra huruvida Inneh&aring;ll f&ouml;ljer de inneh&aring;llsm&auml;ssiga krav som uppst&auml;lls i Villkoren och kan n&auml;r som helst ta bort Inneh&aring;ll och/eller avsluta en Anv&auml;ndares tillg&aring;ng till att l&auml;gga upp Inneh&aring;ll som &auml;r i strid med Villkoren utan f&ouml;reg&aring;ende meddelande och efter eget gottfinnande.</p>
<p>6.8 Du &auml;r inf&ouml;rst&aring;dd med och medveten om, att vid anv&auml;ndande av Tj&auml;nsten, kan du bli exponerad f&ouml;r Inneh&aring;ll som &auml;r sakligt felaktigt, kr&auml;nkande, otillst&auml;ndigt eller p&aring; annat s&auml;tt anst&ouml;tligt. Du avs&auml;ger dig h&auml;rmed r&auml;tten att &aring;beropa n&aring;gra legala r&auml;ttigheter, som du har eller kan ha gentemot PRP h&auml;nf&ouml;rliga till s&aring;dant Inneh&aring;ll, i den omfattning som till&aring;ts enligt till&auml;mplig lag.</p>
<p><strong>7. Personuppgifter</strong></p>
<p>7.1 Du f&ouml;rst&aring;r och accepterar att personuppgifter (ben&auml;mns &rdquo;Personuppgifter&rdquo;) innefattar (men begr&auml;nsas inte till) namn och mailadress samt audiovisuella upptagningar, som sparas och delas i samband med att du anv&auml;nder tj&auml;nsten</p>
<p>7.2. Du f&ouml;rst&aring;r och accepterar att det inneh&aring;ll (huvudsakligen audiovisuella upptagningar) som du skapar genom att anv&auml;nda tj&auml;nsten i allm&auml;nhet sparas i 30 dagar (men kan sparas upp till 90 dagar eller l&auml;ngre) om inte annat anges.</p>
<p>7.3 Du samtycker till att GTG och PRP:</p>
<ol type="i">
<li>
<p><span style="color: #000000;">Sparar dina personuppgifter </span></p>
</li>
<li>
<p><span style="color: #000000;">Delar dina personuppgifter med tredje part om det sker i syfte att tillhandah&aring;lla tj&auml;nsten (t.ex. Google)</span></p>
</li>
<li>
<p><span style="color: #000000;">Skickar dig l&auml;nkar och information som du kan vidarebefordra till andra och d&auml;rigenom vidaref&ouml;rmedla dina personuppgifter</span></p>
</li>
<li>
<p><span style="color: #000000;">Delar dina personuppgifter med andra individer och f&ouml;retag som anv&auml;nder Tj&auml;nsten</span></p>
</li>
</ol>
<p>7.4. Du som anv&auml;ndare av intygar och godk&auml;nner att du:</p>
<ol type="i">
<li>
<p><span style="color: #000000;">Sj&auml;lv nyttjar den tennisbana, fr&aring;n vilken du reglerar Live-Streaming och Video-On-Demand (via dator/tablet/padda/smartphone)</span></p>
</li>
<li>
<p><span style="color: #000000;">Har informerat och f&aring;tt samtycke fr&aring;n &ouml;vriga individer, vars personuppgifter du kan komma att dela genom ditt anv&auml;ndande av Tj&auml;nsten, om att deras personuppgifter kommer att delas i enlighet med Villkoren.</span></p>
</li>
<li>
<p><span style="color: #000000;">Att du inte p&aring;b&ouml;rjar anv&auml;ndandet av Tj&auml;nsten om n&aring;gon individ, vars personuppgifter du kan komma att dela genom ditt anv&auml;ndande av tj&auml;nsten, om inte uttryckligen har samtyckt till att dennes personuppgifter kommer att delas i enlighet med Villkoren.</span></p>
</li>
<li>
<p><span style="color: #000000;">Att du omedelbart avbryter ditt anv&auml;ndande tj&auml;nsten om du misst&auml;nker att n&aring;gon individ, vars personuppgifter du kan komma att dela genom ditt anv&auml;ndande av tj&auml;nsten, inte uttryckligen har samtyckt till att dennes personuppgifter kommer att delas i enlighet med Villkoren.</span></p>
</li>
<li>
<p><span style="color: #000000;">Att du f&ouml;rbinder dig att radera audiovisuella upptagningar och andra personuppgifter, samt att du omedelbart kontaktar PRP, om du misst&auml;nker att n&aring;gon annans personuppgifter sparas eller delas, om den individen inte uttryckligen har samtyckt till att dennes personuppgifter kommer att sparas och delas i enlighet med Villkoren. </span></p>
</li>
</ol>
<p><a name="_heading=h.gjdgxs"></a> 7.5. Du som anv&auml;ndare av tj&auml;nsten kan n&auml;rsomhelst ta bort alla personuppgifter genom att kontakta PRP p&aring;: <a href="mailto:hey@playreplay.io"><span style="color: #0563c1;"><u>hey@playreplay.io</u></span></a></p>
<p>7.6. Du &auml;r medveten om att du anv&auml;nder en kostnadsfri version av Tj&auml;nsten och att varken GTG eller PRP kan garantera att dina personuppgifter skyddas. Du &auml;r ocks&aring; medveten om att tj&auml;nsten vid senare tillf&auml;llen kan komma att kostnadss&auml;ttas, vilket inneb&auml;r att du d&aring; kommer f&aring; betala f&ouml;r den tj&auml;nst som GTG och PRP tillhandah&aring;ller.</p>
<p><strong>8. R&auml;ttigheter du licensierar</strong></p>
<p>8.1 N&auml;r du anv&auml;nder tj&auml;nsten och laddar upp ett ett Inneh&aring;ll till PRP beviljar du:</p>
<p>8.1.1 Till PRP en v&auml;rldsomfattande, icke-exklusiv, kostnadsfri och &ouml;verl&aring;tbar licens (med r&auml;tt att underlicensiera) att anv&auml;nda, reproducera, sprida, g&ouml;ra bearbetningar av, visa och framf&ouml;ra Inneh&aring;llet i samband med tillhandah&aring;llandet av Tj&auml;nsten och annars i samband med tillhandah&aring;llandet av Tj&auml;nsten och PRR verksamhet, innefattande men inte begr&auml;nsat till, marknadsf&ouml;ring och vidaredistribution av Tj&auml;nsten eller delar av Tj&auml;nsten (och bearbetningar d&auml;rav) i vilka mediaformat som helst och genom vilka mediakanaler som helst.</p>
<p>8.1.2. till varje anv&auml;ndare av Tj&auml;nsten en v&auml;rldsomfattande, icke-exklusiv och kostnadsfri licens att f&aring; tillg&aring;ng till dina Inneh&aring;ll genom Tj&auml;nsten och att anv&auml;nda, reproducera, sprida, g&ouml;ra bearbetningar av, visa och framf&ouml;ra s&aring;dana Inneh&aring;ll i den utstr&auml;ckning som till&aring;ts av Tj&auml;nstens funktioner och av dessa Villkor.</p>
<p>8.2 Ovanst&aring;ende licenser som du beviljar till Inneh&aring;ll upph&ouml;r att g&auml;lla n&auml;r du tar bort eller raderar ditt Inneh&aring;ll fr&aring;n Webbplatsen. Ovanst&aring;ende licenser som beviljats av dig till kommentarer i text som du tillg&auml;ngliggjort som Inneh&aring;ll &auml;r eviga och o&aring;terkalleliga, men inverkar inte i &ouml;vrigt p&aring; din &auml;gander&auml;tt som du bibeh&aring;ller enligt punkten 6.2 ovan.</p>
<p><strong>9. PRP-material som tillhandah&aring;lls via Medier</strong></p>
<p>9.1 Med undantag f&ouml;r Inneh&aring;ll tillg&auml;ngliggjort av dig &auml;r allt annat material p&aring; Tj&auml;nsten antingen &auml;gt av eller licensierat till PRP och &auml;r f&ouml;rem&aring;l f&ouml;r upphovsr&auml;tt, varum&auml;rkesr&auml;tt och andra immaterialr&auml;ttsliga r&auml;ttigheter tillh&ouml;rande PRP eller PRPs licensgivare. Andra f&ouml;rekommande varum&auml;rken eller servicem&auml;rken i Inneh&aring;ll som inte tillg&auml;ngliggjorts av dig tillh&ouml;r respektive r&auml;ttighetsinnehavare. S&aring;dant Inneh&aring;ll f&aring;r inte oavsett syfte, laddas ner, kopieras, reproduceras, spridas, skickas, s&auml;ndas, f&ouml;revisas, s&auml;ljas, licensieras eller annars utnyttjas utan f&ouml;reg&aring;ende skriftligt samtycke fr&aring;n PRP eller PRPs licensgivare. PRP och dess licensgivare f&ouml;rbeh&aring;ller sig alla r&auml;ttigheter som inte &auml;r uttryckligen beviljade i eller till deras Inneh&aring;ll.</p>
<p><strong>10. L&auml;nkar fr&aring;n PRP</strong></p>
<p>10.1 Tj&auml;nsten kan inneh&aring;lla hyperl&auml;nkar till andra webbsidor som inte &auml;gs eller &auml;r kontrollerade av PRP. PRP har ingen kontroll &ouml;ver och tar inget ansvar f&ouml;r inneh&aring;llet, integritetspolicys eller hantering p&aring; andra webbsidor.</p>
<p>10.2 Du &auml;r medveten om och accepterar att PRP inte &auml;r ansvarig f&ouml;r tillg&auml;ngligheten av s&aring;dana externa sidor eller k&auml;llor och st&ouml;djer inte reklam, produkter eller annat material p&aring; eller tillg&auml;ngligt fr&aring;n s&aring;dana webbsidor eller k&auml;llor.</p>
<p>10.3 Du &auml;r inf&ouml;rst&aring;dd med och accepterar att PRP inte &auml;r ansvarig f&ouml;r f&ouml;rlust eller skada som kan &aring;samkas dig genom tillg&auml;ngligheten till dessa externa sidor eller k&auml;llor, eller genom att du f&ouml;rlitar dig p&aring; fullst&auml;ndigheten, riktigheten och existensen av reklam, produkter eller annat material som finns tillg&auml;ngligt p&aring; eller fr&aring;n s&aring;dana sidor eller k&auml;llor.</p>
<p>10.4 PRP uppmuntrar dig att vara uppm&auml;rksam n&auml;r du l&auml;mnar Tj&auml;nsten och att l&auml;sa villkor och integritetspolicys p&aring; varje annan webbsida du bes&ouml;ker.</p>
<p><strong>11. Att avsluta ditt f&ouml;rh&aring;llande till PRP</strong></p>
<p>11.1 Villkoren forts&auml;tter att vara till&auml;mpliga tills du eller PRP avslutar desamma p&aring; s&auml;tt som beskrivs nedan.</p>
<p>11.2 Om du vill s&auml;ga upp ditt juridiska avtal med PRP kan du s&aring; g&ouml;ra genom att (a) meddela PRP vid vilken tidpunkt som helst och (b) avsluta ditt anv&auml;ndarkonto. Ditt meddelande skall vara skriftligt och skickas till den adress som anges inledningsvis i dessa Villkor.</p>
<p>11.3 PRP kan vid vilken tidpunkt som helst, s&auml;ga upp sitt juridiska avtal med dig om:</p>
<p>11.3.1. Du har brutit mot n&aring;gon av best&auml;mmelserna i anv&auml;ndarvillkoren (eller har upptr&auml;tt p&aring; ett s&auml;tt som klart visar att du inte avser eller inte kan f&ouml;lja best&auml;mmelserna i anv&auml;ndarvillkoren); eller</p>
<p>11.3.2. PRP &auml;r skyldigt att avsluta avtalet enligt lag (t.ex. om tillhandah&aring;llandet av Tj&auml;nsten till dig &auml;r eller blir otill&aring;tet).</p>
<p>11.4 PRP kan vid vilken tidpunkt som helst, s&auml;ga upp sitt avtal med dig om:</p>
<p>11.4.1 PRP &ouml;verg&aring;r till att inte l&auml;ngre erbjuda Tj&auml;nsten till anv&auml;ndare i landet d&auml;r du &auml;r bosatt eller fr&aring;n vilket du anv&auml;nder Tj&auml;nsten; eller</p>
<p>11.4.2 Tillhandah&aring;llandet av Tj&auml;nsten till dig, enligt PRPs uppfattning, inte l&auml;ngre &auml;r kommersiellt genomf&ouml;rbart.</p>
<p>och i fr&aring;ga om var och en av A eller B i denna klausul 11.4, om m&ouml;jligt, ge rimligt varsel om s&aring;dan upps&auml;gning.</p>
<p>11.5 N&auml;r Villkoren slutar g&auml;lla, skall alla de legala r&auml;ttigheter, f&ouml;rpliktelser och skyldigheter som du och PRP dragit nytta av, varit f&ouml;rem&aring;l f&ouml;r (eller som tillkommit under tiden villkoren varit g&auml;llande) eller vilka ger uttryck f&ouml;r att vara g&auml;llande p&aring; obest&auml;md tid, inte p&aring;verkas av detta upph&ouml;rande och punkten 14.6 skall forts&auml;tta att g&auml;lla p&aring; obest&auml;md tid f&ouml;r s&aring;dana r&auml;ttigheter, f&ouml;rpliktelser och skyldigheter.</p>
<p>12. Uteslutande av Garantier</p>
<p>12.1 Tj&auml;nsten tillhandah&aring;lls &rdquo;i befintligt skick&rdquo; och PRP ger inga garantier till dig i f&ouml;rh&aring;llande till Tj&auml;nsten.</p>
<p>12.3 PRP garanterar speciellt att inte:</p>
<p>12.3.1. Din anv&auml;ndning av Tj&auml;nsten kommer att uppfylla dina krav,</p>
<p>12.3.2. Din anv&auml;ndning av Tj&auml;nsten kommer att fungera utan st&ouml;rning, i r&auml;tt tid, &auml;r s&auml;ker eller fri fr&aring;n felaktigheter,</p>
<p>12.3.3. Information du erh&aring;ller genom din anv&auml;ndning av Tj&auml;nsten &auml;r korrekt eller tillf&ouml;rlitlig, och</p>
<p>12.3.4. brister i driften eller funktionaliteten hos mjukvara som tillhandah&aring;lls dig som en del av Tj&auml;nsten kommer r&auml;ttas till.</p>
<p>12.4 Inga f&ouml;ruts&auml;ttningar, garantier eller andra villkor (innefattande underf&ouml;rst&aring;dda villkor som tillfredsst&auml;llande kvalitet, &auml;ndam&aring;lsenlighet eller &ouml;verensst&auml;mmelse med beskrivningar) kan g&ouml;ras g&auml;llande i f&ouml;rh&aring;llande till Tj&auml;nsten ut&ouml;ver i den omfattning som uttryckligen anges i Villkoren.</p>
<p><strong>13. Ansvarsbegr&auml;nsningar</strong></p>
<p>13.2 PRP &auml;r inte ansvarig gentemot dig f&ouml;r:</p>
<p>13.2.1. N&aring;gra indirekta skador eller f&ouml;ljdskador som &aring;samkats dig. Detta inkluderar f&ouml;rlust av inkomst (vare sig orsakat direkt eller indirekt), f&ouml;rlust av goodwill eller renomm&eacute; eller f&ouml;rlust av data som du lider;</p>
<p>13.2.2. F&ouml;rlust eller skada som &aring;samkas dig som en f&ouml;ljd av:</p>
<p>i. Tilltro som du satt till fullst&auml;ndigheten, riktigheten eller existensen av marknadsf&ouml;ring, eller som en f&ouml;ljd av mellanhavanden eller en transaktion som genomf&ouml;rts mellan dig och en annons&ouml;r eller sponsor vars marknadsf&ouml;ring f&ouml;rekommer p&aring; Tj&auml;nsten;</p>
<p>ii. &Auml;ndringar som PRP f&ouml;retar avseende Tj&auml;nsten, eller f&ouml;r permanenta eller tempor&auml;ra avbrott i tillhandah&aring;llandet av Tj&auml;nsten (eller funktioner hos Tj&auml;nsten);</p>
<p>iii. Avl&auml;gsnande av, f&ouml;rvanskning av eller utebliven lagring av Inneh&aring;ll och andra kommunikationsdata som bevaras eller vidarebefordras genom din anv&auml;ndning av Tj&auml;nsten;</p>
<p>iv. Att du inte lyckas tillhandah&aring;lla PRP riktig kontoinformation;</p>
<p>v. Att du inte lyckas h&aring;lla l&ouml;senord eller information om ditt anv&auml;ndarkontot s&auml;kert och konfidentiellt;</p>
<p>13.3 Begr&auml;nsningarna i PRP ansvar gentemot dig i punkten 13.1 ovan &auml;r till&auml;mpliga oavsett om PRP underr&auml;ttats om eller borde ha varit medveten om m&ouml;jligheten att s&aring;dana f&ouml;rluster kan komma att uppst&aring;.</p>
<p><strong>14. Allm&auml;nna juridiska villkor</strong></p>
<p>14.1 Villkoren utg&ouml;r det fullst&auml;ndiga villkoren mellan dig och PRP och reglerar din anv&auml;ndning av Tj&auml;nsten. Villkoren ers&auml;tter alla tidigare avtal som ber&ouml;r Tj&auml;nsten och som ing&aring;tts mellan dig och PRP.</p>
<p>14.2 Du godk&auml;nner att PRP kan komma att f&ouml;rse dig med meddelanden, innefattande underr&auml;ttelser om &auml;ndringar av Villkoren, via email, post eller genom anslag p&aring; Tj&auml;nsten.</p>
<p>14.3 Du godk&auml;nner att om PRP inte ut&ouml;var eller g&ouml;r g&auml;llande r&auml;ttigheter som anges i Villkoren (eller som PRP kan begagna sig av under till&auml;mplig lag), s&aring; inneb&auml;r det inte att PRP har avst&aring;tt fr&aring;n att tillvarata sina r&auml;ttigheter utan dessa kan n&auml;r som helst g&ouml;ras g&auml;llande av PRP.</p>
<p>14.4 Om n&aring;gon domstol, med jurisdiktion att besluta i s&aring;dant &auml;rende, sl&aring;r fast att n&aring;gon best&auml;mmelse i dessa Villkor &auml;r ogiltig skall den best&auml;mmelsen tas bort fr&aring;n villkoren utan att Villkoren i &ouml;vrigt p&aring;verkas. De &aring;terst&aring;ende best&auml;mmelserna i Villkoren skall forts&auml;tta att vara giltiga och verkst&auml;llbara.</p>
<p>14.5 Du &auml;r medveten om och godk&auml;nner att varje f&ouml;retag som ing&aring;r i samma koncern som PRP &auml;r en ber&auml;ttigad tredje man i f&ouml;rh&aring;llande till Anv&auml;ndarvillkoren och att ett s&aring;dant f&ouml;retag &auml;r ber&auml;ttigat att direkt verkst&auml;lla och f&ouml;rlita sig p&aring; varje best&auml;mmelse i Villkoren som ger dem en f&ouml;rdel eller r&auml;ttighet. Ut&ouml;ver detta skall ingen annan person och inget annat f&ouml;retag vara en ber&auml;ttigad tredje man i f&ouml;rh&aring;llande till Villkoren.</p>
<p>14.6 Villkoren, och din relation till PRP under Villkoren, regleras av svensk r&auml;tt. Du och PRP godk&auml;nner att ni underkastar er exklusivt domstolarnas i Sverige jurisdiktion f&ouml;r att l&ouml;sa eventuella r&auml;ttsliga angel&auml;genheter som uppkommer till f&ouml;ljd av Villkoren. Oaktat detta godk&auml;nner du att PRP &auml;r ber&auml;ttigad att v&auml;nda sig till valfri jurisdiktion f&ouml;r att ans&ouml;ka om interimistiska &aring;tg&auml;rder (eller andra likartade typer av br&aring;dskande legala &aring;tg&auml;rder).</p>
<p><strong>15. Tidsperiod</strong></p>
<p>Dessa Villkor g&auml;ller from 1 september 2019 och tills vidare. PRP och/eller GTG f&ouml;rbeh&aring;ller sig r&auml;tten att n&auml;rsomhelst &auml;ndra Villkoren. Kontaktuppgift till PRP &auml;r <a href="mailto:hey@playreplay.io"><span style="color: #0563c1;"><u>hey@playreplay.io</u></span></a>. Du hittar den senaste versionen av integritetspolicyn under <span style="color: #0563c1;"><u><a href="https://gtg.playreplay.io/gtg/privacy-policy">https://gtg.playreplay.io/gtg/privacy-policy</a></u></span> och senaste versionen av anv&auml;ndaravtalet under <span style="color: #0563c1;"><u><a href="https://gtg.playreplay.io/gtg/privacy-policy">https://gtg.playreplay.io/gtg/terms-conditions</a></u></span>. Vi uppmanar dig att p&aring; l&ouml;pande basis bes&ouml;ka <span style="color: #0563c1;"><u><a href="https://gtg.playreplay.io/gtg/privacy-policy">https://gtg.playreplay.io/gtg/privacy-policy</a></u></span> samt <span style="color: #0563c1;"><u><a href="https://gtg.playreplay.io/gtg/terms-conditions">https://gtg.playreplay.io/gtg/terms-conditions</a></u></span> f&ouml;r att h&aring;lla dig uppdaterad om eventuella f&ouml;r&auml;ndringar.</p>
<p><br /> </p>`
              }}
            ></div>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button primary floated="left" onClick={this.handleClick}>
                OK
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default TermsAndConditions;
