import React, { Component } from 'react'
import SVG from 'react-inlinesvg'

import firebase from 'firebase/app'

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

import closeIcon from '../../../assets/icons/close.svg'

import s from './index.module.sass'

const auth = firebase.auth()

const uiConfig = {
  credentialHelper: 'none',
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
  ],
  tosUrl: '/gtg/terms-conditions',
  privacyPolicyUrl: '/gtg/privacy-policy'
}

class Login extends Component {

  constructor (props) {
    super(props)
    this.goBack = this.goBack.bind(this)
  }

  state = {
    pagename: null
  }

  goBack () {
    this.props.history.goBack();
  }

  componentDidMount () {
    const {match: {params}} = this.props
    const pageName = params.pageName

    this.setState({
      pagename: pageName
    })
  }

  render () {

    uiConfig.signInSuccessUrl = '/web/' + this.state.pagename
    return (
      <React.Fragment>
        <div className={`${s.link} pointer`}onClick={this.goBack}>
          <SVG src={closeIcon}/>
        </div>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
      </React.Fragment>
    )
  }
}

export default Login
