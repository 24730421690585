import React from 'react'
import logo from '../../../assets/images/white_gtg_logo.png'

import s from './index.module.sass'

const link = 'https://goodtogreat.se'

function Footer () {
  return (
    <footer className={`${s.footer} on-dark-background-text`}>
      <div> In collaboration with:</div>
      <img className={s.logo} src={logo} alt="logo"/>
      <a className={`on-dark-background-link`} href={link}>Good to Great Tennis Academy</a>
    </footer>
  )
}

export default Footer