import React, { Component } from "react";
import { Button, Container, Grid } from "semantic-ui-react";

class PrivacyPolicy extends Component {
  handleClick = () => {
    this.props.history.push("/web/login");
  };
  render() {
    return (
      <Container>
        <Grid>
          <Grid.Row>
            <div
              className={`tablet-pages`}
              dangerouslySetInnerHTML={{
                __html: `
            <p><strong>Integritetspolicy (Privacy policy)</strong></p>
            <p><strong>Allm&auml;nt</strong></p>
            <p>PlayReplay AB, org. nr 559199-3554 (ben&auml;mns &rdquo;PRP&rdquo;), tillhandah&aring;ller tj&auml;nster f&ouml;r live-streaming (sv. str&ouml;mma) och Video-on-Demand i samarbete med <span style="color: #000000;">GoodToGreat World AB, 556812-7095 (ben&auml;mns GTG&rdquo;). All data som samlas in av PRP kan komma att anv&auml;ndas av GTG, och vice versa, i samband med tillhandah&aring;llande av tj&auml;nster till dig som slutanv&auml;ndare. PRP och GTG ben&auml;mns nedan gemensamt eller var f&ouml;r sig som &rdquo;Leverant&ouml;ren&rdquo;.</span></p>
            <p><strong>Vilken typ av data samlar vi in fr&aring;n dig?</strong></p>
            <p>Leverant&ouml;ren samlar in f&ouml;ljande typer av data:</p>
            <ul>
            <li>
            <p><span style="color: #000000;">Personuppgifter: Personuppgifter som leverant&ouml;ren samlar in inkluderar, men begr&auml;nsas n&ouml;dv&auml;ndigtvis </span>inte<span style="color: #000000;"> till, ditt f&ouml;rnamn, efternamn och din e-postadress</span></p>
            </li>
            <li>
            <p><span style="color: #000000;">Audiovisuella upptagningar som du v&auml;ljer att spela in, spara och s&auml;nda/ live-streama (sv. str&ouml;mma)</span></p>
            </li>
            <li>
            <p><span style="color: #000000;">Trafikuppgifter s&aring;som loggning av ip-nummer, trafik, inloggningar och liknande h&auml;ndelser</span></p>
            </li>
            </ul>
            <p><strong>N&auml;r samlar vi in data (inklusive personuppgifter)?</strong></p>
            <p>Leverant&ouml;ren samlar in data (inkl. personuppgifter) vid f&ouml;ljande scenarion:</p>
            <ul>
            <li>
            <p><span style="color: #000000;">N&auml;r du som anv&auml;ndare v&auml;ljer att skapa ett konto hos PRP och sj&auml;lv fylla i uppgifter om dig sj&auml;lv</span></p>
            </li>
            <li>
            <p><span style="color: #000000;">N&auml;r du v&auml;ljer att anv&auml;nda tj&auml;nster som PRP tillhandah&aring;ller. Tj&auml;nster innefattar (men begr&auml;nsas inte till) att s&auml;nda, spara, och spela upp audiovisuella upptagningar till en begr&auml;nsad krets eller allm&auml;nheten via internet </span></p>
            </li>
            <li>
            <p><span style="color: #000000;">N&auml;r du anv&auml;nder n&aring;gon av Leverant&ouml;rens &rdquo;Medier&rdquo; (t.ex. tablet eller webbsida) </span></p>
            </li>
            </ul>
            <p><strong>Varf&ouml;r samlar vi in data?</strong></p>
            <p>Leverant&ouml;ren samlar in data f&ouml;r att kunna</p>
            <ul>
            <li>
            <p><a name="_heading=h.gjdgxs"></a> <span style="color: #000000;">Tillhandah&aring;lla tj&auml;nster till dig. Tj&auml;nster innefattar (men begr&auml;nsas inte till) att s&auml;nda, spara, och spela upp audiovisuella upptagningar till en begr&auml;nsad krets eller allm&auml;nheten via internet </span></p>
            </li>
            </ul>
            <ul>
            <li>
            <p><span style="color: #000000;">Kommunicera med dig, t.ex. skicka dig e-mail inneh&aring;llandes l&auml;nkar med video-str&ouml;mmar, mail-autentisering, och</span> meddelanden om<span style="color: #000000;"> &aring;terst&auml;llning av l&ouml;senord</span></p>
            </li>
            <li>
            <p><span style="color: #000000;">Erbjuda en effektiv och skr&auml;ddarsydd anv&auml;ndarupplevelse i samband med att du anv&auml;nder n&aring;gon av leverant&ouml;rens &rdquo;Medier&rdquo; (t.ex. tablet eller webbsida)</span></p>
            </li>
            <li>
            <p><span style="color: #000000;">Utveckla nya tj&auml;nster som du eller andra anv&auml;ndare skall kunna anv&auml;nda i framtiden.</span></p>
            </li>
            </ul>
            <p><span style="color: #000000;">Grunden f&ouml;r Leverant&ouml;rens datainsamling &auml;r baserat p&aring; det avtal som ing&aring;tts mellan Leverant&ouml;ren och anv&auml;ndaren, d.v.s. du som anv&auml;ndare har genom ett avtal bekr&auml;ftat att du godk&auml;nner anv&auml;ndarvillkoren, som beskriver att Leverant&ouml;ren, samt PRP och GTG var f&ouml;r sig, har r&auml;ttighet att spara din data f&ouml;r att kunna tillhandah&aring;lla tj&auml;nsten.</span></p>
            <p><strong>Vilka delar vi din data med?</strong></p>
            <p>Leverant&ouml;ren delar personuppgifter med externa leverant&ouml;rer f&ouml;r att kunna fullg&ouml;ra leverans och hantering av v&aring;ra tj&auml;nster, i vissa fall &auml;ven utanf&ouml;r EU/EES, f&ouml;r att kunna tillhandah&aring;lla beg&auml;rda tj&auml;nster eller f&ouml;r att i marknadsf&ouml;ringssyfte kunna erbjuda dig dina tj&auml;nster.</p>
            <p><strong>Hur l&auml;nge lagrar vi uppgifter?</strong></p>
            <p>Leverant&ouml;ren, samt PRP och/eller GTG var f&ouml;r sig, kan komma att lagra din data till dess att PRP och/eller GTG upph&ouml;r att erbjuda tj&auml;nsten. Om du inte har anv&auml;nt tj&auml;nsten p&aring; 36 m&aring;nader s&aring; kommer PRP radera dina personuppgifter. Du kan n&auml;rsomhelst be att f&aring; alla din uppgifter raderade genom att kontakta PRP p&aring; <a href="mailto:hey@playreplay.io"><span style="color: #0563c1;"><u>hey@playreplay.io</u></span></a>.</p>
            <p><strong>Giltighet och &auml;ndringar</strong></p>
            <p><span style="color: #000000;">Denna integritetspolicy g&auml;ller from den 1 september 2019 och tills vidare. PRP och/eller GTG f&ouml;rbeh&aring;ller sig r&auml;tten att n&auml;rsomhelst &auml;ndra integritetspolicyn</span><span style="color: #000000;"> och anv&auml;ndaravtalet.</span> Du hittar den senaste versionen av integritetspolicyn under <span style="color: #0000ff;"><u><a href="https://gtg.playreplay.io/gtg/privacy-policy">https://gtg.playreplay.io/gtg/privacy-policy</a></u></span> och senaste versionen av anv&auml;ndaravtalet under <span style="color: #0000ff;"><u><a href="https://gtg.playreplay.io/gtg/privacy-policy">https://gtg.playreplay.io/gtg/terms-conditions</a></u></span>. Vi uppmanar dig att p&aring; l&ouml;pande basis bes&ouml;ka <span style="color: #0000ff;"><u><a href="https://gtg.playreplay.io/gtg/privacy-policy">https://gtg.playreplay.io/gtg/privacy-policy</a></u></span> samt <span style="color: #0000ff;"><u><a href="https://gtg.playreplay.io/gtg/terms-conditions">https://gtg.playreplay.io/gtg/terms-conditions</a></u></span> f&ouml;r att h&aring;lla dig uppdaterad om eventuella f&ouml;r&auml;ndringar.</p>
            <p><br /> </p>
            `
              }}
            ></div>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button primary floated="left" onClick={this.handleClick}>
                OK
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default PrivacyPolicy;
