import DropIn from "braintree-web-drop-in-react";
import React from 'react';
import { sendToServer, getClientToken } from '../../services/common'

class CardPaymentView extends React.Component {
    instance;

    state = {
        clientToken: null
    };

    async componentDidMount() {
        // Get a client token for authorization from your server
        const response = await getClientToken();
        const clientToken = await response.clientToken;

        this.setState({
            clientToken
        });
    }

    async getToken() {
        const { nonce } = await this.instance.requestPaymentMethod();
        return nonce;
    }

    async buy(nonce) {
        const amount = '110.00';
        const response = await sendToServer({ nonce: nonce, amount: amount }, 'createTransaction', 'POST');
        return response;
    }


    render() {

        if (!this.state.clientToken) {
            return (
                <div className={"loading-text"}>
                    <h1>Loading...</h1>
                </div>
            );
        }

        return (
            <div>
                <DropIn
                    options={
                        {
                            authorization: this.state.clientToken,
                            // threeDSecure: true,
                            card: {
                                cardholderName: {
                                    required: true
                                },
                                overrides: {
                                    fields: {
                                        number: {
                                            maskInput: {
                                                showLastFour: true
                                            }
                                        },
                                        cvv: {
                                            maskInput: true
                                        }
                                    }
                                }
                            }
                        }
                    }
                    onInstance={instance => (this.instance = instance)}
                />
            </div>
        );
    }

}


export default CardPaymentView;
