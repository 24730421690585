import axios from "axios";
import firebase from "firebase/app";
const auth = firebase.auth();
const db = firebase.firestore();
export const storages = {
  idToken: "idToken"
};

export const serverAddress =
  "https://us-central1-bjornborg-b24cc.cloudfunctions.net/v1";

export const fetchAPI = (route, method = "GET", body = {}, options) => {
  return new Promise((resolve, reject) => {
    method = method.toLocaleLowerCase();
    let url = `${serverAddress}/${route}`;
    let headers = {
      "Content-Type": "application/json",
      idtoken: localStorage.getItem(storages.idToken)
    };
    axios.request({
        method,
        url,
        headers,
        ...options,
        data: body
      })
      .then(result => {
        resolve(result.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const sendToServer = (payload, route, method = 'POST', success, error) => {
    return new Promise((resolve, reject) => {
        return fetchAPI(route, method, {
            ...payload
        })
            .then(proc => {
                resolve(proc)
            })
            .catch(error => {
                console.log(error);
                reject(error)
            })
    })
};

export const getClientToken = () => {
    return new Promise((fullFill, reject) => {
        fetchAPI(`getClientToken`, "GET")
            .then(proc => {
                fullFill(proc);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });

};

export const getRequest = (url) => {
    let headers = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    };

    return new Promise((resolve, reject) => {
        axios.get(url, headers)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            });
    });

}

export const postRequest = (url) => {
    // const headers = {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Methods': 'POST',
    //     'Access-Control-Allow-Headers': 'X-Custom-Header'
    // };
    // let config = {
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         'Access-Control-Allow-Origin': '*',
    //         'Access-Control-Allow-Methods': '*',
    //         'Access-Control-Allow-Headers': '*'
    //     },
    // };

    return new Promise((resolve, reject) => {
        axios.post(url, {})
            .then(response => {
                // console.log('response', response);
                resolve(response.data)
            })
            .catch(error => {
                console.log('error ', error)
                reject(error)
            });
    });

};

export const getAuthToken = () => {
  return new Promise((fullFill, reject) => {
    auth.currentUser
      .getIdTokenResult()
      .then(idToken => {
        localStorage.setItem(storages.idToken, idToken.token);
        fullFill(idToken);
      })
      .catch(err => {
        reject(false);
      });
  });
};

export const downloadFile = (url) => {

    axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'video.mp4');
        document.body.appendChild(link);
        link.click();
    });

};
