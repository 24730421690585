import React, { Component } from 'react'
import Navbar from '../../../components/specific/header/navbar'
import { Header, Grid } from 'semantic-ui-react'
import VideoPreview from '../../../components/common/video-preview'
import { NoVideo } from '../../../components/common/no-video'

import firebase from 'firebase/app'
import Swal from 'sweetalert2'
import VideoPreviews from '../../../components/common/video-previews'

const db = firebase.firestore()

class Archive extends Component {
  state = {
    publicVideos: null,
  }

  warningNotVerified () {

    Swal.fire({
      title: 'Email verification',
      text: 'In order to watch \'public archive\' and \'my-videos\' you need to verify your email address. We\'ve sent you an email.',
      type: 'warning'
    }).then(() => { })
  }

  retrieveData () {
    db.collection('sports')
      .doc('tennis')
      .collection('videos')
      .limit(50)
      .where('isPublic', '==', true)
      .where('isLive', '==', false)
      .orderBy('startTime_date', 'desc')
      .onSnapshot(querySnapshot => {

        this.setState({publicVideos: []})
        querySnapshot.forEach(doc => {
          let docInfo = doc.data()
          docInfo.id = doc.id
          this.setState({
            publicVideos: [...this.state.publicVideos, docInfo]
          })
        })
      })
  }

  authListener () {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (!user.emailVerified) {
          this.props.history.push('/')
          this.warningNotVerified()
          return
        }
        this.retrieveData()
      }
    })
  }

  componentDidMount () {
    this.authListener()
  }

  startViewer = videoId => {
    return () => {
      this.props.history.push('/web/stream/' + videoId + '/persisted')
    }
  }

  render () {
    const {publicVideos} = this.state

    return (
      <React.Fragment>
        <div>
          <Navbar/>
          <div className={`container`}>
            <h2>Public Videos</h2>
            <VideoPreviews
              previews={publicVideos}
              onClick={this.startViewer}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Archive
