import React from 'react'

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import 'adapterjs'
import './firebase-config'
import firebase from 'firebase/app'
import WebLogin from './routes/web/login'
import TermsAndConditions from './routes/web/terms-conditions'
import PrivacyPolicy from './routes/web/privacy-policy'
import PaymentForm from './routes/web/payment-form'
import InfoPayment from './routes/web/info-payment'
import Dashboard from './routes/web/dashboard'
import OngoingStreams from './routes/web/ongoing-streams'
import MyVideos from './routes/web/my-videos'
import Archive from './routes/web/archive'
import StreamViewer from './routes/web/streamviewer'

import {getAuthToken, sendToServer} from './services/common'
import { AppContext, DEFAULT_CONTEXT } from './context.js'
import 'semantic-ui-css/semantic.min.css'
import './App.css'
import './styles/global.sass'
import SlackFeedback from "react-slack-feedback";

const auth = firebase.auth();

class App extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      appContext: DEFAULT_CONTEXT,
    }
  }

  handleUpdateContext (firebaseUser) {
    const {appContext} = this.state;

    const user = {
      ...appContext.user,
      isLoggedIn: !!firebaseUser,
      data: firebaseUser
    };

    console.log('Setting user context', user);

    this.setState({
      appContext: {
        ...appContext,
        user
      },
    })
  }

  componentDidMount () {

    auth.onAuthStateChanged(user => {
      this.handleUpdateContext(user);

      if (user) {
        getAuthToken()  // Put the token in local storage
      }
    })
  }

  render () {
    return (
      <main>
        <AppContext.Provider value={this.state.appContext}>
          <Router>
            <Switch>

              {/* Web routes */}
              <Route path="/web/dashboard/ongoing" component={OngoingStreams}/>
              <Route path="/web/my-videos" component={MyVideos}/>
              <Route path="/web/archive" component={Archive}/>
              <Route path="/web/pay" component={PaymentForm}/>
              <Route path="/web/info-pay" component={InfoPayment}/>

              <Route path="/web/login/:pageName" component={WebLogin}/>
              <Route path="/web/stream/:videoId/:videoType" component={StreamViewer}/>
              <Route
                path="/gtg/terms-conditions"
                component={TermsAndConditions}
              />
              <Route
                path="/gtg/privacy-policy"
                component={PrivacyPolicy}
              />
              <Route path="/" exact component={Dashboard}/>

              <Redirect from='*' to='/'/>
            </Switch>
          </Router>
          <SlackFeedback
              channel="#feedback"
              user="Slack Feedback" // The logged in user (default = "Unknown User")
              onSubmit={(payload, success, error) =>
                  sendToServer(payload, 'feedback', 'POST')
                      .then(success)
                      .catch(error)
              }
          />
        </AppContext.Provider>
      </main>
    )
  }
}

export default App
